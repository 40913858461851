<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3">
      <v-card class="radius-card pa-3 pt-0" :loading="loading">
        <div class="py-3 d-flex justify-space-between">
          <h3>Voucher Management</h3>
          <v-btn depressed color="blue" dark to="/admin/create-voucher"
            >Generate Voucher</v-btn
          >
        </div>
        <div class="mt-3">
          <v-card class="text-center rounded-lg pa-2" outlined> </v-card>
          <div class="mt-3" v-if="list">
            <v-data-table
              :headers="headers"
              :items="list"
              hide-default-footer
              :page.sync="page"
              :items-per-page="limit"
              class="elevation-0 my-5"
            >
              <template v-slot:[`item.kode`]="{ item }">
                <v-menu
                  v-model="item.menu"
                  :nudge-bottom="10"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="elementFocused(item)"
                      depressed
                    >
                      {{ item.kode }}
                    </v-btn>
                  </template>
                  <v-card
                    class="pa-2 d-flex align-center"
                    color="purple lighten-5"
                    flat
                  >
                    <v-text-field
                      :id="`vcr-${item.id}`"
                      outlined
                      v-model="tempVcr"
                      hide-details
                      dense
                      background-color="#fff"
                      @keyup.enter="updateKode(item)"
                      class="mr-2"
                    ></v-text-field>
                    <v-btn
                      depressed
                      style="min-width:0"
                      @click="updateKode(item)"
                      color="blue"
                      dark
                    >
                      <v-icon v-if="!updating" small>mdi-check</v-icon>
                      <v-progress-circular
                        v-else
                        size="20"
                        indeterminate
                        small
                      ></v-progress-circular>
                    </v-btn>
                  </v-card>
                </v-menu>
              </template>
              <template v-slot:[`item.layanan`]="{ item }">
                {{ item.layanan.deskripsi.nama }}
              </template>
              <template v-slot:[`item.expire`]="{ item }">
                <p class="ma-0 small_txt color_txt">
                  {{ item.expire }}
                </p>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  depressed
                  color="blue"
                  class="mr-2"
                  dark
                  :to="`/admin/update-voucher/${item.id}`"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn depressed color="red" dark @click="delVcr(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>

            <v-pagination
              v-model="page"
              circle
              @input="fetchItems"
              :length="last_page"
            ></v-pagination>
            <v-row>
              <v-col cols="2">
                <p class="mb-0">Tampilkan</p>
                <div class="d-flex align-center">
                  <v-select
                    :items="listLimit"
                    v-model="limit"
                    dense
                    hide-details
                    outlined
                    @change="fetchItems"
                  ></v-select>
                  <p class="ml-1 mb-0">Data</p>
                </div>
              </v-col>
            </v-row>
            <div class="pa-2">
              <!-- <create-voucher
                :d_cvcr="d_cvcr"
                @refetch="fetchItems"
                @close="d_cvcr = false"
              /> -->
              <delete-voucher
                :d_dvcr="d_dvcr"
                @refetch="fetchItems"
                :inventory="inventory"
                @close="d_dvcr = false"
              />
              <!-- <UpdateVoucher
                :d_uvcr="d_uvcr"
                @refetch="fetchItems"
                :inventory="inventory"
                @close="d_uvcr = false"
              /> -->
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
// import CreateVoucher from "./createVoucher.vue";
import DeleteVoucher from "../BATCH3/Counseling/Admin/deleteVoucher.vue";
// import UpdateVoucher from "./updateVoucher.vue";

export default {
  name: "listVoucher",
  components: {
    //  CreateVoucher,
    DeleteVoucher,
    // UpdateVoucher
  },
  data() {
    return {
      list: null,
      last_page: null,
      showMenu: false,
      d_cvcr: false,
      d_dvcr: false,
      d_uvcr: false,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "KODE",
          align: "start",
          value: "kode",
        },
        {
          text: "LAYANAN",
          align: "start",
          value: "layanan",
        },
        {
          text: "KUOTA",
          align: "start",
          value: "kuota",
        },
        {
          text: "EXPIRED DATE",
          align: "start",
          value: "expire",
        },
        {
          text: "ACTIONS",
          align: "start",
          value: "actions",
        },
      ],
      find: "",
      page: 1,
      limit: 10,
      listLimit: [5, 10, 20, 50, 70, 100],
      dialogAlert: false,
      tab: "tab-1",
      dataUser: null,
      d_profilePsy: false,
      d_profileClient: false,
      d_evidence: false,
      updating: false,
      loading: false,
      tempVcr: "",
      inventory: null,
    };
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      this.loading = true;
      let data = {
        page: this.page,
        limit: this.limit,
        find: this.find,
      };
      this.$store.dispatch("counseling/listVoucher", data).then((data) => {
        this.list = data.data.data;
        this.last_page = data.data.last_page;
        this.list.forEach((el) => {
          el["menu"] = false;
          el["expire"] = this.$date(`${el.expire_date} ${el.expire_time}`)
            .locale("id")
            .format("dddd, DD MMM YYYY | HH:mm");
        });
        this.loading = false;
      });
    },
    elementFocused(item) {
      this.tempVcr = `-${item.kode}`.slice(1);
      setTimeout(() => {
        document.getElementById(`vcr-${item.id}`).select();
      }, 100);
    },
    delVcr(item) {
      this.inventory = item;
      this.d_dvcr = true;
    },
    updateVcr(item) {
      let data = {
        id: item.id,
        kode: item.kode,
        deskripsi: item.deskripsi,
        expire_date: item.expire_date,
        expire_time: item.expire_time,
        kuota: item.kuota,
        layanan_id: item.layanan.layanan_id,
      };
      this.inventory = data;
      this.d_uvcr = true;
    },
    updateKode(item) {
      this.updating = true;
      let body = {
        id: item.id,
        kode: this.tempVcr,
      };
      this.$store
        .dispatch("counseling/updateVcr", body)
        .then((data) => {
          this.updating = false;
          this.fetchItems();
          item.menu = false;
          Swal.fire({
            title: data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            toast: true,
          });
        })
        .catch((err) => {
          this.updating = false;

          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}
.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}
.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}
.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}
.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}
.bagi3 {
  width: calc(100% / 3);
}
</style>
