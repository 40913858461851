<template>
  <v-row justify="start">
    <v-dialog v-model="d_dvcr" persistent max-width="370px">
      <v-card class="radius-card hide_overflow" flat>
        <div class="d-flex justify-end px-2">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon></v-btn
          >
        </div>
        <div
          class="d-flex flex-column align-center justify-center pa-3"
          style="min-height: 230px"
          v-if="inventory"
        >
          <div>
            <div class="d-flex justify-center">
              <v-btn
                @click="deleteItem"
                class="text-capitalize py-6 mr-4"
                dark
                color="red"
                width="100px"
                depressed
                >Delete</v-btn
              >
              <v-btn
                class="text-capitalize py-6"
                @click="$emit('close')"
                outlined
                color="red"
                width="100px"
                depressed
                >Cancel</v-btn
              >
            </div>
          </div>
          <v-overlay
            :absolute="true"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="purple"
            ></v-progress-circular>
            <b class="purple--text">Loading...</b>
          </v-overlay>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "deleteVcr",
  props: ["d_dvcr", "inventory"],

  data() {
    return {
      role: "",
      loading: false,
      reason: "",
      isReject: false
    };
  },
  methods: {
    deleteItem() {
      this.loading = true;
      let body = {
        id: this.inventory.id
      };
      this.$store
        .dispatch("counseling/deleteVcr", body)
        .then(data => {
          this.$emit("close");
          this.$emit("refetch");
          this.loading = false;
          this.isReject = false;
          Swal.fire({
            title: data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            toast: true
          });
        })
        .catch(err => {
          this.loading = false;
          Swal.fire({
            title: err.message,
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            toast: true
          });
          console.log(err);
        });
    }
  }
};
</script>
