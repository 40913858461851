var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_bg-default _80w _100vh"},[_c('div',{staticClass:"_100top pa-3"},[_c('v-card',{staticClass:"radius-card pa-3 pt-0",attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"py-3 d-flex justify-space-between"},[_c('h3',[_vm._v("Voucher Management")]),_c('v-btn',{attrs:{"depressed":"","color":"blue","dark":"","to":"/admin/create-voucher"}},[_vm._v("Generate Voucher")])],1),_c('div',{staticClass:"mt-3"},[_c('v-card',{staticClass:"text-center rounded-lg pa-2",attrs:{"outlined":""}}),(_vm.list)?_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-0 my-5",attrs:{"headers":_vm.headers,"items":_vm.list,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.limit},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.kode",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"nudge-bottom":10,"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":""},on:{"click":function($event){return _vm.elementFocused(item)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.kode)+" ")])]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('v-card',{staticClass:"pa-2 d-flex align-center",attrs:{"color":"purple lighten-5","flat":""}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"id":("vcr-" + (item.id)),"outlined":"","hide-details":"","dense":"","background-color":"#fff"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateKode(item)}},model:{value:(_vm.tempVcr),callback:function ($$v) {_vm.tempVcr=$$v},expression:"tempVcr"}}),_c('v-btn',{staticStyle:{"min-width":"0"},attrs:{"depressed":"","color":"blue","dark":""},on:{"click":function($event){return _vm.updateKode(item)}}},[(!_vm.updating)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_c('v-progress-circular',{attrs:{"size":"20","indeterminate":"","small":""}})],1)],1)],1)]}},{key:"item.layanan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.layanan.deskripsi.nama)+" ")]}},{key:"item.expire",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v(" "+_vm._s(item.expire)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"blue","dark":"","to":("/admin/update-voucher/" + (item.id))}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"depressed":"","color":"red","dark":""},on:{"click":function($event){return _vm.delVcr(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)}),_c('v-pagination',{attrs:{"circle":"","length":_vm.last_page},on:{"input":_vm.fetchItems},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Tampilkan")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{attrs:{"items":_vm.listLimit,"dense":"","hide-details":"","outlined":""},on:{"change":_vm.fetchItems},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}}),_c('p',{staticClass:"ml-1 mb-0"},[_vm._v("Data")])],1)])],1),_c('div',{staticClass:"pa-2"},[_c('delete-voucher',{attrs:{"d_dvcr":_vm.d_dvcr,"inventory":_vm.inventory},on:{"refetch":_vm.fetchItems,"close":function($event){_vm.d_dvcr = false}}})],1)],1):_vm._e()],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }